import redirects from "../../../redirects";

const typedRedirects = redirects as { source: string; destination: string }[];

export const classicRenaming: { [key: string]: string } = {
  "/products/the-bed": "/products/classic-bed",
  "/products/underbed-storage-drawer": "/products/classic-underbed-drawer",
  "/products/headboard-cushion": "/products/classic-headboard-cushion",
  "/products/the-headboard": "/products/classic-headboard",
  "/products/the-daybed": "/products/classic-daybed",
  "/products/the-tray": "/products/classic-bedside-tray",
  "/products/the-bookshelf": "/products/pillar-bookshelf",
  "/products/the-rug": "/products/marbled-wool-rug",
  "/products/the-dresser": "/products/nest-dresser",
  "/products/the-credenza": "/products/nest-credenza",
  "/products/the-shelving": "/products/nest-shelving",
  "/products/the-cubby": "/products/nest-shelf",
  "/products/the-bookshelf-shelves": "/products/pillar-bookshelf-shelves",
  "/products/the-dresser-add-ons": "/products/nest-drawer",
  "/products/the-two-drawer-nightstand": "/products/nest-nightstand",
  "/products/one-drawer-nightstand": "/products/nest-nightstand-low",
  "/products/the-open-nightstand": "/products/nest-nightstand-open",
  "/products/the-nightstand": "/products/classic-nightstand",
  "/products/nightstand-legs": "/products/classic-nightstand-legs",
  "/products/the-sleeve": "/products/felt-sleeve",
  "/products/the-bolster": "/products/pillowboard-bolsters",
  "/products/underbed-storage-bin": "/products/felt-underbed-bin",
  "/products/the-hybrid-mattress": "/products/hybrid-mattress",
  "/products/have-some-respect-coasters": "/products/felt-coasters",
  "/products/complete-cotton-percale-bedding-set":
    "/products/cotton-percale-complete-bedding-set",
  "/products/complete-linen-bedding-set":
    "/products/linen-complete-bedding-set",
  "/products/down-alternative-duvet": "/products/down-alternative-duvet-insert",
  "/products/lounge-leisure-socks": "/products/crew-knit-socks",
  "/products/the-gift-card": "/products/gift-card",
  "/products/lounge-leisure-shorts": "/products/midweight-terry-shorts",
  "/products/lounge-leisure-sweatpants": "/products/midweight-terry-sweatpants",
  "/products/lounge-leisure-hoodie": "/products/midweight-terry-hoodie",
  "/products/favorite-hoodie-blanket": "/products/midweight-terry-blanket",
};

export const redirectsMap = {
  ...classicRenaming,
  ...typedRedirects.reduce<{ [key: string]: string }>(
    (acc, { source, destination }) => ({
      ...acc,
      [source]: destination,
    }),
    {}
  ),
};
