import { FC } from "react";

import { ICollectionAssembly } from "../../../../../@types/generated/contentful";
import { CollectionAssembly } from "../../CollectionAssembly";
import { CollectionAssemblyItem } from "../../CollectionAssembly/CollectionAssembly";
import { RenderSectionsProps } from "../types";
import { assetToImageData } from "../util";

export default function CollectionAssemblySaddle({
  content: {
    fields: {
      name,
      title,
      collectionCtaText,
      collectionSections,
      carouselConfig: { fields: rawConfig },
    },
  },
  RenderSectionsComponent,
}: {
  content: ICollectionAssembly;
  RenderSectionsComponent: FC<RenderSectionsProps>;
}) {
  const formattedItems: CollectionAssemblyItem[] = collectionSections
    .filter((section) => section.sys.contentType.sys.id === "collectionSection")
    .map(({ fields: section }) => ({
      title: section.previewTitle || section.title,
      description: section.previewDescription || section.description,
      anchor: section.sectionAnchor,
      image: section.previewImage
        ? assetToImageData(section.previewImage)
        : null,
    }));

  return (
    <CollectionAssembly
      name={name}
      title={title}
      ctaText={collectionCtaText}
      items={formattedItems}
      carouselConfig={{
        mobileCards: rawConfig.numberOfMobileCarouselCards,
        mobilePeek: rawConfig.mobilePeekPercentage,
        tabletCards: rawConfig.numberOfTabletCarouselCards,
        tabletPeek: rawConfig.tabletPeekPercentage,
        desktopCards: rawConfig.numberOfDesktopCarouselCards,
        desktopPeek: rawConfig.desktopPeekPercentage,
        showProgressBar: rawConfig.showCarouselScrollBar,
        showPagination: rawConfig.showCarouselPaginationButtons,
      }}
    >
      <RenderSectionsComponent sections={collectionSections} />
    </CollectionAssembly>
  );
}
