import { ComponentProps, ReactNode } from "react";
import { DynamicGrid } from "@components";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";
import { ProductTileContextProvider } from "src/lib/context/ProductTileContext";
import { AnalyticsProvider } from "src/lib/hooks/useAnalytics";
import useCatalog from "src/lib/hooks/useCatalog";
import useCollectionPageUrl from "src/lib/hooks/useCollectionPageUrl";
import usePriceFormatter from "src/lib/hooks/usePriceFormatter";
import {
  formatCmsItems,
  FormattedCollection,
  FormattedCollectionItem,
  validateCmsItems,
} from "src/lib/queries/data/collectionData";
import { ProductCatalog } from "src/lib/services/catalog/types";
import {
  addProductSelectEvent,
  addViewCollectionEvent,
} from "src/lib/services/elevar/events";
import { getShopifyId } from "src/lib/services/util/shopifyID";
import { isTruthy } from "src/lib/util";

import {
  ICollectionItem,
  ICollectionSection,
  ITextOverlayMedia,
} from "../../../../../@types/generated/contentful";
import { getFormattedPrice } from "../../CollectionListSection/CollectionListSection";
import ProductCard from "../../CollectionListSection/components/ProductCard";

import TextOverlayMediaSaddle from "./TextOverlayMediaSaddle";

type CollectionSectionItem =
  ICollectionSection["fields"]["mobileItems"][number];

/**
 * Type guard to check if an item is of type ICollectionItem.
 * @param item - The item to check.
 * @returns True if the item is a collection item, otherwise false.
 */
export const isCollectionItem = (
  item: CollectionSectionItem
): item is ICollectionItem => item.sys.contentType.sys.id === "collectionItem";

type DynamicGridItem = ComponentProps<typeof DynamicGrid>["items"][number];

/**
 * Combines and filters mobile and desktop collection items.
 * @param mobileItems - List of mobile items.
 * @param desktopItems - Optional list of desktop items.
 * @returns A filtered array of all collection items.
 */
export const getAllCollectionItems = (
  mobileItems: CollectionSectionItem[],
  desktopItems?: CollectionSectionItem[]
): ICollectionItem[] => [
  ...mobileItems.filter(isCollectionItem),
  ...(desktopItems?.filter(isCollectionItem) || []),
];

/**
 * Extracts unique product IDs from a list of collection items.
 * @param collectionItems - The collection items to process.
 * @returns An array of unique product IDs.
 */
export const getProductIds = (collectionItems: ICollectionItem[]): string[] =>
  Array.from(
    new Set(
      collectionItems.map((item) =>
        getShopifyId(item.fields.product.fields.product)
      )
    )
  );

/**
 * Handles the click event for a product card, triggering a product select event.
 * @param item - The collection item that was clicked.
 * @param list - The list name where the item is displayed.
 * @param position - The position of the item in the list.
 */
const handleCardClick = (
  item: FormattedCollectionItem,
  list: string,
  position: number
) =>
  addProductSelectEvent(
    {
      id: String(item.id),
      name: item.title,
      brand: item.vendor ?? "Thuma",
      category: item.productType,
      variant: item.variants[0].title || "",
      price: String(item.price),
      quantity: "1",
      list,
      product_id: String(item.id),
      variant_id: item.variants[0].id || "",
      compare_at_price: "",
      image: item.image?.src,
      position: position + 1,
    },
    list
  );

/**
 * Formats a list of collection section items into dynamic grid items.
 * @param items - The items to format.
 * @param catalog - The product catalog containing item details.
 * @param formatPrice - Function to format the item price.
 * @param collectionPageUrl - The URL of the collection page.
 * @param featuredItemList - Optional list of featured items to highlight.
 * @returns An array of formatted dynamic grid items.
 */
const formatItems = (
  items: CollectionSectionItem[],
  catalog: ProductCatalog,
  formatPrice: (price: string) => string,
  collectionPageUrl: string,
  featuredItemList?: CollectionSectionItem[]
): DynamicGridItem[] =>
  items
    .map((item, index) => {
      const {
        sys: {
          id,
          contentType: {
            sys: { id: contentTypeId },
          },
        },
      } = item;
      const featured = Boolean(
        featuredItemList?.some(
          (featuredItem) => featuredItem.sys.id === item.sys.id
        )
      );
      const analyticsValues = {
        id,
        name: item.fields?.name,
        type: contentTypeId,
      };

      let children: ReactNode;
      let inGridContent = false;
      if (contentTypeId === "collectionItem") {
        const collectionItem = item as ICollectionItem;
        const [formattedItem] = formatCmsItems(
          [collectionItem],
          catalog
        ) as FormattedCollectionItem[];
        // istanbul ignore if
        if (!formattedItem) return null;

        const onClick = () =>
          handleCardClick(formattedItem, collectionPageUrl, index);
        const {
          href,
          title: footerText,
          optionName,
          optionValues,
          image,
        } = formattedItem;
        children = (
          <AnalyticsProvider object={analyticsValues}>
            <ProductTileContextProvider
              value={{ tilePosition: index + 1, visibleOnPageload: "yes" }}
            >
              <ProductCard
                id={formattedItem.id}
                contentfulId={id}
                href={href}
                price={getFormattedPrice(formattedItem, formatPrice)}
                footerText={footerText}
                optionName={optionName}
                optionValues={optionValues}
                onClick={onClick}
                image={image}
                hoverImage={image}
                isFeatured={featured}
                contentfulName={collectionItem.fields.name}
                // @ts-expect-error all variants have ids
                variantId={formattedItem.variants[0].id}
              />
            </ProductTileContextProvider>
          </AnalyticsProvider>
        );
      } else if (contentTypeId === "textOverlayMedia") {
        const textOverlayMedia = item as ITextOverlayMedia;
        inGridContent = true;
        children = (
          <AnalyticsProvider object={analyticsValues}>
            <TextOverlayMediaSaddle
              content={textOverlayMedia}
              isSection={false}
              keepDimensions={{ mobile: true, desktop: false }}
            />
          </AnalyticsProvider>
        );
      } else {
        children = null;
      }

      return {
        id,
        featured,
        children,
        inGridContent,
      };
    })
    .filter(isTruthy);

export default function CollectionSectionSaddle({
  content: {
    fields: {
      title,
      sectionAnchor,
      description,
      toggleButtonTextCollapsed,
      toggleButtonTextExpanded,
      mobileItems,
      desktopItems,
      featuredItems,
      featuredItemsDesktop,
    },
  },
  index,
}: {
  content: ICollectionSection;
  index: number;
}) {
  const formatPrice = usePriceFormatter();
  const collectionPageUrl = useCollectionPageUrl();
  let analyticFormattedItems: FormattedCollectionItem[] = [];

  const allCollectionItems = getAllCollectionItems(mobileItems, desktopItems);
  const productIds = getProductIds(allCollectionItems);
  const observerRef = useRunOnVisibleOnce(
    () => {
      addViewCollectionEvent(analyticFormattedItems, collectionPageUrl);
    },
    { threshold: 0.8 },
    1000
  );

  const { catalog } = useCatalog(productIds);
  if (!catalog) return null;

  try {
    validateCmsItems(allCollectionItems, catalog);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Could not render collection section "${title}"`, err);
    return null;
  }

  const formattedMobileItems = formatItems(
    mobileItems,
    catalog,
    formatPrice,
    collectionPageUrl,
    featuredItems
  );
  const formattedDesktopItems = formatItems(
    desktopItems ?? mobileItems,
    catalog,
    formatPrice,
    collectionPageUrl,
    featuredItemsDesktop ?? featuredItems
  );
  analyticFormattedItems = formatCmsItems(
    allCollectionItems,
    catalog
  ) as FormattedCollection["items"];

  return (
    <section
      id={sectionAnchor}
      data-collection-list-index={index}
      ref={observerRef}
    >
      <ProductTileContextProvider
        value={{
          productListSectionTitle: title,
        }}
      >
        <DynamicGrid
          title={title}
          description={description}
          toggleButtonTextCollapsed={toggleButtonTextCollapsed}
          toggleButtonTextExpanded={toggleButtonTextExpanded}
          items={formattedMobileItems}
          desktopItems={formattedDesktopItems}
        />
      </ProductTileContextProvider>
    </section>
  );
}
