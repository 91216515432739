const redirects = [
  {
    source: "/products/classic-bed-1",
    destination: "/products/classic-bed",
    permanent: true,
  },
  {
    source: "/products/classic-headboard-cushion-1",
    destination: "/products/classic-headboard-cushion",
    permanent: true,
  },
  {
    source: "/products/classic-bed-in-black",
    destination: "/products/classic-bed",
    permanent: true,
  },
  {
    source: "/products/classic-bed-in-black-headboard-cushion",
    destination: "/products/classic-bed",
    permanent: true,
  },
  {
    source: "/products/classic-daybed-in-black",
    destination: "/products/classic-daybed",
    permanent: true,
  },
  {
    source: "/products/classic-headboard-cushion-in-black",
    destination: "/products/classic-headboard-cushion",
    permanent: true,
  },
  {
    source: "/products/classic-headboard-in-black",
    destination: "/products/classic-headboard",
    permanent: true,
  },
  {
    source: "/products/pillar-bookshelf-in-black",
    destination: "/products/pillar-bookshelf",
    permanent: true,
  },
  {
    source: "/products/pillar-bookshelf-shelves-in-black",
    destination: "/products/pillar-bookshelf-shelves",
    permanent: true,
  },
  {
    source: "/products/pillar-bench-1",
    destination: "/products/pillar-bench",
    permanent: true,
  },
  {
    source: "/products/pillar-stool-in-black",
    destination: "/products/pillar-stool",
    permanent: true,
  },
  {
    source: "/products/nest-credenza-1",
    destination: "/products/nest-credenza",
    permanent: true,
  },
  {
    source: "/products/the-drawer-in-black",
    destination: "/products/nest-drawer",
    permanent: true,
  },
  {
    source: "/products/nest-dresser-in-black",
    destination: "/products/nest-dresser",
    permanent: true,
  },
  {
    source: "/products/nest-nightstand-in-black",
    destination: "/products/nest-nightstand",
    permanent: true,
  },
  {
    source: "/products/nest-nightstand-low-in-black",
    destination: "/products/nest-nightstand-low",
    permanent: true,
  },
  {
    source: "/products/nest-nightstand-open-in-black",
    destination: "/products/nest-nightstand-open",
    permanent: true,
  },
  {
    source: "/products/nest-shelf-in-black",
    destination: "/products/nest-shelf",
    permanent: true,
  },
  {
    source: "/products/nest-shelving-in-black",
    destination: "/products/nest-shelving",
    permanent: true,
  },
  {
    source: "/products/classic-nightstand-in-black",
    destination: "/products/classic-nightstand",
    permanent: true,
  },
  {
    source: "/products/c-side-table-in-black",
    destination: "/products/c-side-table",
    permanent: true,
  },
  {
    source: "/products/pedestal-side-table-in-black",
    destination: "/products/pedestal-side-table",
    permanent: true,
  },
  {
    source: "/products/classic-underbed-drawer-in-black",
    destination: "/products/classic-underbed-drawer",
    permanent: true,
  },
];

module.exports = redirects;
