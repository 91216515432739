/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import { Children, PropsWithChildren } from "react";
import { ProductTileContextProvider } from "src/lib/context/ProductTileContext";
import Swiper from "swiper";

import { DeprecatedColumnLimit } from "../../../components/Grid/Grid";
import { useRunOnVisibleOnce } from "../../../hooks/useIntersectionObserver";
import useCollectionPageUrl from "../../hooks/useCollectionPageUrl";
import usePriceFormatter from "../../hooks/usePriceFormatter";
import {
  FormattedAd,
  FormattedCollectionItem,
  FormattedCollectionVariant,
} from "../../queries/data/collectionData";
import {
  addProductSelectEvent,
  addViewCollectionEvent,
} from "../../services/elevar/events";

import { Ad } from "./components/Ad";
import CollectionCarousel from "./components/CollectionCarousel";
import CollectionGrid from "./components/CollectionGrid";
import ProductCard from "./components/ProductCard";
import selectors from "./selectors";

export interface CollectionContentItem extends FormattedCollectionItem {
  onClick?: (item: FormattedCollectionItem) => void;
}

interface CollectionListSectionProps {
  /** An array of objects containing content for the Card component */
  content: CollectionContentItem[];
  /** An array of ads that will be displayed after the product cards */
  ads?: FormattedAd[];
  /** The layout for the list section */
  layout?: "grid" | "carousel" | "shop-the-look";
  /** The max columns to display for the section */
  maxColumns?: DeprecatedColumnLimit;
  onSlideChange?: (swiper: Swiper) => void;
}

export const getMinPrice = (
  variants: Partial<FormattedCollectionVariant>[]
): number =>
  variants.reduce((acc, curr) => Math.min(acc, Number(curr.price)), Infinity);

export const getMaxPrice = (variants: Partial<FormattedCollectionVariant>[]) =>
  variants.reduce((acc, curr) => Math.max(acc, Number(curr.price)), 0);

export const handleCardClick = (
  item: FormattedCollectionItem,
  list: string,
  position: number
) =>
  addProductSelectEvent(
    {
      id: String(item.id),
      name: item.title,
      brand: item.vendor ?? "Thuma",
      category: item.productType,
      variant: item.variants[0].title || "",
      price: String(item.price),
      quantity: "1",
      list,
      product_id: String(item.id),
      variant_id: item.variants[0].id || "",
      compare_at_price: "",
      image: item.image?.src,
      position: position + 1,
    },
    list
  );

export const getFormattedPrice = (
  item: FormattedCollectionItem,
  formatPrice: (price: string) => string
): string => {
  if (item.priceListing) {
    switch (item.priceListing) {
      case "Show Minimum": {
        const min = getMinPrice(item.variants);
        return `From $${min}`; // TODO: Make locale-aware
      }
      case "Show Range": {
        if (item.variants.length > 1) {
          const min = getMinPrice(item.variants);
          const max = getMaxPrice(item.variants);
          return `$${min} - $${max}`;
        }
        break;
      }
      case "Hidden": {
        return "";
      }
      // eslint-disable-next-line no-fallthrough
      default:
        return formatPrice(item.price);
    }
  }
  return formatPrice(item.price);
};

// TODO: depend on actual image dimensions
const FIXED_CARD_DIMENSIONS = 600;

export type CollectionListWrapperProps = React.PropsWithChildren<{
  maxColumns: NonNullable<CollectionListSectionProps["maxColumns"]>;
  onSlideChange?: CollectionListSectionProps["onSlideChange"];
}>;

function Wrapper({
  layout,
  maxColumns,
  children,
  onSlideChange,
}: PropsWithChildren<{
  layout: CollectionListSectionProps["layout"];
  maxColumns: NonNullable<CollectionListSectionProps["maxColumns"]>;
  onSlideChange?: CollectionListSectionProps["onSlideChange"];
}>) {
  switch (layout) {
    case "grid":
      return (
        <ProductTileContextProvider value={{ visibleOnPageload: "yes" }}>
          <CollectionGrid maxColumns={maxColumns}>{children}</CollectionGrid>
        </ProductTileContextProvider>
      );
    case "carousel":
      return (
        <CollectionCarousel
          onSlideChange={onSlideChange}
          maxColumns={maxColumns}
        >
          {Children.map(children, (child, index) => (
            <ProductTileContextProvider
              value={{ visibleOnPageload: index < maxColumns ? "yes" : "no" }}
            >
              {child}
            </ProductTileContextProvider>
          ))}
        </CollectionCarousel>
      );
    case "shop-the-look":
      return (
        <CollectionCarousel
          maxColumns={maxColumns}
          overrides={{
            slidesPerView: {
              base: 1.2,
              twin: 1.2,
              full: 2.2,
              queen: 2.2,
            },
          }}
        >
          {children}
        </CollectionCarousel>
      );
    default:
      return null;
  }
}

/**
 A component that renders a grid/carousel of product cards based on product data
 */
export function CollectionListSection({
  content,
  ads = [],
  layout = "grid",
  maxColumns = 4,
  onSlideChange,
}: CollectionListSectionProps) {
  const formatPrice = usePriceFormatter();
  const isCarouselLayout = layout === "carousel";
  const collectionPageUrl = useCollectionPageUrl();

  const observerRef = useRunOnVisibleOnce(() => {
    addViewCollectionEvent(content, collectionPageUrl);
  });

  return (
    <div
      className="mx-auto max-w-screen-king"
      data-testid={selectors.container}
      ref={observerRef}
    >
      <Wrapper
        maxColumns={maxColumns}
        layout={layout}
        onSlideChange={onSlideChange}
      >
        {content.map((item, index) => {
          const {
            key,
            href,
            title,
            optionName,
            optionValues,
            onClick,
            image: { src, alt },
          } = item;

          return (
            <ProductTileContextProvider
              key={key}
              value={{ tilePosition: index + 1 }}
            >
              <ProductCard
                href={href}
                price={getFormattedPrice(item, formatPrice)}
                footerText={title}
                id={item.id}
                optionName={optionName}
                optionValues={optionValues}
                // @ts-expect-error all variants have ids
                variantId={item.variants[0].id}
                onClick={() => {
                  if (onClick) {
                    onClick(item);
                  } else {
                    handleCardClick(item, collectionPageUrl, index);
                  }
                }}
                image={{
                  src,
                  alt,
                  width: FIXED_CARD_DIMENSIONS,
                  height: FIXED_CARD_DIMENSIONS,
                }}
              />
            </ProductTileContextProvider>
          );
        })}
        {ads.map(
          (
            {
              key,
              background,
              cta,
              title,
              overlayColorTop,
              overlayColorMiddle,
              overlayColorBottom,
              overlayOpacity,
              textOverlayMedia,
            },
            index
          ) => (
            <Ad
              key={key}
              backgroundImage={{
                src: background.url,
                alt: background.alt,
              }}
              ctaTitle={cta?.text}
              ctaUrl={cta?.url}
              title={title}
              inCarousel={isCarouselLayout}
              gridPosition={content.length + index}
              overlayColorTop={overlayColorTop}
              overlayColorMiddle={overlayColorMiddle}
              overlayColorBottom={overlayColorBottom}
              overlayOpacity={overlayOpacity}
              maxColumns={maxColumns}
              textOverlayMedia={textOverlayMedia}
            />
          )
        )}
      </Wrapper>
    </div>
  );
}
